import React from 'react'
import { Box, Divider, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'uppercase'
  },
  image: {
    objectFit: 'contain',
    objectPosition: 'left',
    maxHeight: '100%',
    width: 'auto'
  }
}))

export function BrandsBanner ({ data }) {
  const classes = useStyles()
  const { partnership, partnership_logos } = data
  return (
    <Box py='30px'>
      <Typography classes={{ root: classes.title }}>{partnership}</Typography>
      <Box my='20px'>
        <Divider />
      </Box>
      {partnership_logos.map((l, i) => (
        <Box display='flex' key={`logo${i}`}>
          <Box mr='20px' height='100px' minWidth='100px' maxHeight='50px'>
            <img
              fadeIn='false'
              loading='eager'
              alt='partner'
              src={l.logo.url}
              className={classes.image}
            />
          </Box>
        </Box>
      ))}
    </Box>
  )
}
