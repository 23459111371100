import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { colors } from '../utils/theme'
import { useMobile } from '../utils/useMobile'

const useStyles = makeStyles(() => ({
  dataTitle: {
    textTransform: 'uppercase'
  },
  withStrong: {
    '& strong': {
      fontWeight: 760
    }
  },
  stats0: {
    transform: 'rotate(25deg);',
    zIndex: 0,

    '& > *': {
      transform: 'rotate(-25deg);'
    }
  },
  stats1: {
    zIndex: 2
  },
  stats2: {
    zIndex: 2
  },
  stats3: {
    transform: 'rotate(-25deg);',
    zIndex: 3
  },
  circle: {
    borderRadius: '110px'
  },
  rightSemiCircle: {
    borderTopRightRadius: '110px',
    borderBottomRightRadius: '110px'
  },
  leftSemiCircle: {
    borderTopLeftRadius: '110px',
    borderBottomLeftRadius: '110px'
  }
}))

export function CirclesBanner () {
  const classes = useStyles()
  const mobile = useMobile()

  return (
    <Box
      display='flex'
      my='40px'
      alignItems='center'
      justifyContent='space-between'
    >
      {/* DRAWINGS */}
      <Box
        bgcolor={colors.red}
        height='200px'
        width='100px'
        className={classes.rightSemiCircle}
        display='flex'
        flexDirection='column'
        justifyContent='center'
      >
        <Box
          bgcolor={colors.black}
          height='80px'
          width='40px'
          className={classes.rightSemiCircle}
        />
      </Box>
      <Box
        bgcolor={colors.red}
        height='70px'
        width='35px'
        className={classes.leftSemiCircle}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        ml='50px'
      />
      <Box
        bgcolor={colors.red}
        height='70px'
        width='35px'
        className={classes.rightSemiCircle}
        display='flex'
        flexDirection='column'
        justifyContent='center'
      />
      {!mobile && (
        <>
          <Box
            bgcolor={colors.yellow}
            height='70px'
            width='70px'
            className={classes.circle}
            ml='50px'
          />
          <Box
            bgcolor={colors.darkBlue}
            height='200px'
            width='200px'
            className={classes.circle}
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <Box
              bgcolor={colors.black}
              height='80px'
              width='80px'
              className={classes.circle}
            />
          </Box>
          <Box
            bgcolor={colors.yellow}
            height='70px'
            width='70px'
            className={classes.circle}
            ml='50px'
            mr='100px'
          />
        </>
      )}
    </Box>
  )
}
