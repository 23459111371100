import { Box, makeStyles, Typography, Portal, Link } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { colors } from '../utils/theme'
import { Headline } from '../components/Headline'
import { cx } from '../utils/cx'
import CloseIcon from '@material-ui/icons/Close'
import { useMobile } from '../utils/useMobile'

const useStyles = makeStyles(() => ({
  stats: {
    transition: 'transform 1s',
    '& *': {
      transition: 'transform 1s'
    }
  },
  stats0: {
    transform: 'rotate(25deg)',
    zIndex: 0,

    '& > *': {
      transform: 'rotate(-25deg)'
    }
  },
  stats1: {
    zIndex: 2
  },
  stats2: {
    zIndex: 2
  },
  stats3: {
    transform: 'rotate(-25deg);',
    zIndex: 3
  },
  open: {
    opacity: 1
  },
  hide: {
    opacity: 0,
    pointerEvents: 'none'
  },
  straight: {
    transform: 'rotate(0deg)',
    '& > *': {
      transform: 'rotate(0deg)'
    }
  },
  animated: {
    transition: 'all .7s',
    zIndex: 4,
    width: '100%',
    height: '100%',
    padding: '0 30px',
    top: '0',
    left: '0',
    '& > *': {
      transform: 'none',
      transition: 'all .1s'
    }
  },
  source: {
    lineBreak: 'anywhere',
    fontSize: '.8em',
    fontStyle: 'italic'
  },
  pointer: {
    cursor: 'pointer'
  },
  mobile: {
    '& h4, & p:not(:last-of-type)': {
      fontSize: '1.5rem'
    }
  },
  legend: {
    color: 'currentColor'
  }
}))

export function DataSquares ({ data, animationContainer }) {
  const matches = [...data.matchAll(/\[(.*)\]/g)]
  const stripped = matches.map(m => m[1].split('|'))
  const mapped = stripped.map(arr => ({
    num: arr[0].trim(),
    legend: arr[1].trim(),
    description: arr[2].trim(),
    source: arr[3].trim()
  }))
  const [busy, setBusy] = useState(false)

  return mapped.map((d, i) => (
    <DataBox
      d={d}
      i={i}
      key={`image${i}`}
      animationContainer={animationContainer}
      busy={busy}
      setBusy={setBusy}
    />
  ))
}

function DataBox ({ d, i, animationContainer, busy, setBusy }) {
  const classes = useStyles()
  const bgcolors = [colors.black, colors.lightBlue, colors.red, colors.yellow]
  const [animating, setAnimating] = useState(false)
  const [open, setOpen] = useState(false)
  const innerRef = useRef()
  const color = i === 3 ? colors.black : colors.white
  const mobile = useMobile()

  return (
    <>
      <Box
        color={color}
        className={cx(classes.withStrong, mobile && classes.mobile)}
        position='relative'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Box
          width='100%'
          height='100%'
          position='absolute'
          top={0}
          left={0}
          bgcolor={bgcolors[i]}
          className={cx(
            classes[`stats${i}`],
            classes.stats,
            (animating || open) && classes.straight
          )}
          onMouseEnter={e => {
            if (busy) return
            setAnimating(true)
          }}
          onMouseLeave={e => {
            if (
              e.relatedTarget === innerRef.current ||
              innerRef.current.contains(e.relatedTarget)
            ) {
              return
            }
            if (!open) {
              setAnimating(false)
            }
          }}
        />
        <Box
          zIndex='4'
          position='relative'
          onMouseEnter={e => {
            if (busy || animating) return
            setAnimating(true)
          }}
          ref={innerRef}
        >
          <Typography variant='h4'>
            <strong>{d.num}</strong>
          </Typography>
          <Box fontStyle='italic'>
            <Headline>{d.legend}</Headline>
          </Box>
          <Typography
            variant='h5'
            classes={{ root: classes.pointer }}
            onClick={e => {
              setOpen(true)
            }}
          >
            <strong>+</strong>
          </Typography>
        </Box>
      </Box>
      <Portal container={animationContainer.current}>
        <Box
          position='absolute'
          bgcolor={bgcolors[i]}
          color={color}
          classes={{
            root: cx(
              classes.animated,
              !open && classes.hide,
              open && classes.open,
              mobile && classes.mobile
            )
          }}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          onTransitionEnd={() => {
            setBusy(false)
            if (animating) {
              setAnimating(false)
              setOpen(true)
            }
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              setOpen(false)
            }, 1000)
          }}
        >
          <Typography
            paragraph
            classes={{
              root: cx(!open && classes.hide)
            }}
            variant='h4'
          >
            {d.description}
          </Typography>
          <Typography
            variant='body2'
            classes={{
              root: cx(classes.source, !open && classes.hide)
            }}
          >
            <HyperlinkSouce source={d.source} />
          </Typography>
          <Box position='absolute' top='20px' right='20px'>
            <CloseIcon
              onClick={() => setOpen(false)}
              className={classes.pointer}
            />
          </Box>
        </Box>
      </Portal>
    </>
  )
}

function HyperlinkSouce ({ source }) {
  const split = source.match(/(.+?:)(.+)(http.+)/)
  const classes = useStyles()

  return (
    <>
      {split[1].trim()}{' '}
      <Link
        href={split[3].trim()}
        target='_blank'
        classes={{ root: classes.legend }}
      >
        {split[2].trim()}
      </Link>
    </>
  )
}
