import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { useRef } from 'react'
import { Container } from '../components/Container'
import { HorizontalHeadline } from '../components/HorizontalHeadline'
import { Page } from '../components/Page'
import { HalfHalfWrapper } from '../components/HalfHalfWrapper'
import { colors, respSectionPadding } from '../utils/theme'
import { Slider } from '../components/Slider'
import { Headline } from '../components/Headline'
import { CirclesBanner } from '../components/CirclesBanner'
import { Divider } from '../components/Divider'
import { FullPage } from '../components/FullPage'
import { cx } from '../utils/cx'
import { useMobile } from '../utils/useMobile'
import { ID } from '../components/ID'
import { FullPageBrandsBanner } from '../components/FullPageBrandsBanner'
import { Loader } from '../components/Loader'
import { richText } from '../utils/richText'
import { DataSquares } from '../components/DataSquares'
import { lyricsStyle, withLyrics } from '../utils/withLyrics'
import { carouselFontSize } from '../utils/carouselFontSize'
import { useCoreData } from '../utils/useCoreData'

const useStyles = makeStyles(() => ({
  dataTitle: {
    textTransform: 'uppercase'
  },
  withStrong: {
    '& strong': {
      fontWeight: 600
    }
  },
  stats0: {
    transform: 'rotate(25deg);',
    zIndex: 0,

    '& > *': {
      transform: 'rotate(-25deg);'
    }
  },
  stats1: {
    zIndex: 2
  },
  stats2: {
    zIndex: 2
  },
  stats3: {
    transform: 'rotate(-25deg);',
    zIndex: 3
  },
  paragraph: {
    display: 'block',
    marginBottom: '14px'
  },
  box: {
    width: '90%',

    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%'
    }
  },
  small: {
    '& h5': {
      fontSize: '18px !important'
    }
  },
  ...lyricsStyle
}))

export default function OurWork ({ data }) {
  return (
    <Page>
      <OurWorkContent />
    </Page>
  )
}

function OurWorkContent () {
  const classes = useStyles()
  const mobile = useMobile()
  const bannerContainer = useRef()
  const {
    api: {
      data: { our_work }
    }
  } = useCoreData()

  if (!our_work) return <Loader />

  const {
    sentence_carousel,
    stats_image_data,
    stats_image_title,
    stats_text1,
    stats_text2,
    stats_text_title1,
    stats_text_title2,
    what_we_do_text,
    what_we_do_title,
    circle_banner_content_1,
    circle_banner_title_1,
    circle_banner_content_2,
    circle_banner_title_2,
    circle_banner_content_3,
    circle_banner_title_3
  } = our_work

  return (
    <>
      <Container withToolbar>
        <Box mt={respSectionPadding(mobile) + 'px'}>
          <ID id='what_we_do' />
          <HorizontalHeadline
            paragraph
            title={richText(what_we_do_title)}
            content={richText(what_we_do_text)}
          />
        </Box>
      </Container>
      <Container unwrap={mobile}>
        <HalfHalfWrapper
          classes={{ root: mobile && classes.small }}
          pt={respSectionPadding(mobile) + 'px'}
          leftBgColor={colors.green}
          rightBgColor={colors.lightGrey}
          leftContent={
            <Slider interactable>
              {sentence_carousel.map((s, i) => (
                <Box
                  px={mobile ? '30px' : '70px'}
                  py='40px'
                  height='100%'
                  textAlign='center'
                  display='flex'
                  key={`slide_${i}`}
                  color={colors.white}
                  className={cx(classes.lyrics, mobile && classes.small)}
                >
                  <Headline variant={carouselFontSize(s)} condensed>
                    {richText(withLyrics(s.text))}
                  </Headline>
                  <Box textAlign='center' mt='14px'>
                    <Typography variant='subtitle2' key={`${s}${i}`}>
                      {s.author}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Slider>
          }
          rightContent={
            <Box
              px='30px'
              pt='40px'
              height='100%'
              textAlign='center'
              justifyContent='space-between'
              flexDirection='column'
              display='flex'
              ref={bannerContainer}
              position='relative'
            >
              <Typography variant='h5' classes={{ root: classes.dataTitle }}>
                {richText(stats_image_title)}
              </Typography>
              <Box
                mx='auto'
                position='relative'
                classes={{ root: cx(classes.box) }}
                display='flex'
                justifyContent='center'
                alignItems='flex-end'
              >
                <Box
                  display='grid'
                  gridTemplateColumns='repeat(2, 1fr)'
                  gridTemplateRows='repeat(2, 1fr)'
                  position='absolute'
                  width='90%'
                  height='90%'
                >
                  <DataSquares
                    data={stats_image_data}
                    animationContainer={bannerContainer}
                  />
                </Box>
              </Box>
            </Box>
          }
        />
      </Container>
      <FullPage bgcolor={colors.black} color={colors.white} my='70px' py='50px'>
        <Container>
          <Typography
            variant='h5'
            component='span'
            classes={{ root: cx(classes.dataTitle, classes.paragraph) }}
          >
            {richText(stats_text_title1)}
          </Typography>
          <Headline component='span'>{richText(stats_text1)}</Headline>
        </Container>
        <CirclesBanner />
        <Container>
          <Box
            display='grid'
            gridTemplateColumns={mobile ? '1fr' : 'repeat(3,1fr)'}
          >
            <Box
              display='flex'
              flexDirection='column'
              pr={mobile ? '' : '50px'}
            >
              <Typography variant='h6' classes={{ root: classes.withStrong }}>
                <strong>{circle_banner_title_1}</strong>
              </Typography>
              <Headline>{richText(circle_banner_content_1)}</Headline>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              pr={mobile ? '' : '50px'}
              mt={mobile && '30px'}
            >
              <Typography variant='h6' classes={{ root: classes.withStrong }}>
                <strong>{circle_banner_title_2}</strong>
              </Typography>
              <Headline>{richText(circle_banner_content_2)}</Headline>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              pr={mobile ? '' : '50px'}
              mt={mobile && '30px'}
            >
              <Typography variant='h6' classes={{ root: classes.withStrong }}>
                <strong>{circle_banner_title_3}</strong>
              </Typography>
              <Headline>
                <Headline>{richText(circle_banner_content_3)}</Headline>
              </Headline>
            </Box>
          </Box>
        </Container>
      </FullPage>
      {stats_text_title2.text && stats_text2.text && (
        <Container>
          <Box
            mt={respSectionPadding(mobile) + 'px'}
            mb={mobile ? respSectionPadding(mobile) + 'px' : ''}
          >
            <Typography
              variant='h5'
              component='span'
              classes={{ root: cx(classes.dataTitle, classes.paragraph) }}
            >
              {richText(stats_text_title2)}
            </Typography>
            <Headline component='span'>{richText(stats_text2)}</Headline>
          </Box>
          {!mobile && <Divider />}
        </Container>
      )}
      <FullPageBrandsBanner />
    </>
  )
}
